import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import logo from '../images/logo.svg';
import * as styles from  './header.module.css';

const Header = ({ siteTitle }) => (
  <div className={styles.headerBar}>
    <Link to="/" className={styles.logo}>
      <img src={logo}></img>
    </Link>

    <Link to="/" className={styles.contactMe}>
      Contact me
    </Link>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
